import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'


const surveys = [
  {
    icon: faClipboardList,
    bgColor: "bg-accentColor-100",
    title: "Lorem ipsum dolor sit",
    text: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat`,
    to: "/mobility",
  },
  {
    icon: faClipboardList,
    bgColor: "bg-accentColor-200",
    title: "labore et dolore magna",
    text: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat`,
    to: "/mobility",
  },
  {
    icon: faClipboardList,
    bgColor: "bg-accentColor-300",
    title: "Lorem ipsum dolor sit",
    text: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat`,
    to: "/mobility",
  }
];

const AllSurveys = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full">
      <div className="bg-LightBG-200 p-5 text-center md:text-left">
        <h3 className="text-xl md:text-2xl font-bold ">At vero eos et accusam</h3>
        <p className="text-left text-base">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt</p>
        <p className="text-left text-base">labore et dolore magna aliquyam erat, sed diam voluptua.</p>

      </div>
      <section className="flex justify-center items-center flex-grow">
        <div className="container my-12 mx-auto px-4">
          <div className="flex flex-wrap -mx-1">
            {surveys.map((sur, i) => {
              return (
                <div key={i} className="my-1 px-1 w-full z-50 md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                  <article className="flex flex-col items-center overflow-hidden border p-6 text-center md:text-left">
                    <div className={` relative inline-flex items-center justify-center w-32 h-32 overflow-hidden ${sur.bgColor} rounded-full border`}>
                      <span className="font-medium text-blue-600 dark:text-gray-300">
                        <FontAwesomeIcon icon={sur.icon} className="text-primaryColor fa-4x " />
                      </span>
                    </div>
                    <h5 className="text-lg font-bold pb-5">{sur.title}</h5>
                    <p className="text-sm text-darkGrayishBlue pb-5">{sur.text}</p>
                    <Link
                      className="px-6 py-2 w-full text-center font-typeFont
                    text-white bg-primaryColor  baseline 
                    hover:bg-blue-600 "
                      to={sur.to}
                    >
                      Starten
                    </Link>
                  </article>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AllSurveys;
