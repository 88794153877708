import React from 'react'

import PrimaryButton from '../../components/PrimaryButton';
import SidaMessage from '../tools/SidaMessage';

const AppIntro = () => {
    return (

        <section className="h-full bg-primaryBG flex justify-center md:items-center py-10">
            <div className="container flex flex-col gap-5  ">

                <SidaMessage
                    charW={"100px"}
                    small={true}
                    text={`Hallo, ich bin Sida,​ dein MS-Coach.

                    Ich kann dir helfen deine Symptome der Multiplen Sklerose besser zu beschreiben, zu verstehen und
                    deren Veränderungen im Blick zu behalten, damit du sie mit deinem Arzt besprechen kannst.
                    
                    Ich unterstütze dich gerne dabei dich auf den nächsten Arzttermin vorzubereiten, um im Gespräch alle dir wichtigen Themen anzusprechen.`}
                />
                <SidaMessage
                    charW="100px"
                    small={true}
                    // typeEffect={true}
                    text={`Wenn Du einen bestimmten Fragebogen suchst, hier geht es zu unserer Bibliothek.`}
                />


                <div className="flex self-center">
                    <PrimaryButton text={"Bibliothek"} to={"library"} typ="dark" />
                </div>
            </div>
        </section>

    )
}

export default AppIntro