import React from 'react'

const Loader = ({ aboulte = true }) => {

    return (
        <div className={`${aboulte ? "absoluteBox" : "flex justify-center items-center h-full gap-3"} bg-primaryBG`}>
            <div className="loader bg-blue-600"></div>
            <div className="loader bg-blue-500"></div>
            <div className="loader bg-blue-400"></div>
        </div>
    )
}

export default Loader