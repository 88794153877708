// const json = {
//   "locale": "de",
//   "title": {
//     "default": "Sida, dein MS-Coach",
//     "de": "Hallo ich bin Sida, dein MS-Coach"
//   },
//   "description": "Ich kann dir helfen deine Symptome der Multiplen Sklerose besser zu beschreiben, zu verstehen und deren Veränderungen im Blick zu behalten, damit du sie mit deinem Arzt besprechen kannst.",
//   "logoPosition": "right",
//   "completedHtml": "<h3>Schön, dass du dir die Zeit genommen hast, die Fragen zu beantworten. Es gibt einige Beschwerden, die wir noch genauer anschauen sollen. Anhand deiner Antworten schlage ich vor, dass du folgende Fragen beantwortest und den Fragebogen beim nächsten Termin deinem Arzt mitbringst. Legen wir direkt los!</h3>",
//   "pages": [
//     {
//       "name": "introduction",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "FrageBzgDiagnose",
//           "title": {
//             "de": "Eine wichtige Frage vorab: hat ein Arzt bei dir die Diagnose Multiple Sklerose (MS) gestellt?"
//           },
//           "isRequired": true,
//           "choices": [
//             {
//               "value": "ja",
//               "text": {
//                 "de": "Ja"
//               }
//             },
//             {
//               "value": "nein",
//               "text": {
//                 "de": "Nein"
//               }
//             },
//             {
//               "value": "idk",
//               "text": {
//                 "de": "Ich weiß nicht"
//               }
//             }
//           ]
//         },
//         {
//           "type": "expression",
//           "name": "question5",
//           "visibleIf": "({FrageBzgDiagnose} = 'nein') or ({FrageBzgDiagnose} = 'idk')",
//           "title": {
//             "de": "Eine Diagnose kann nur von einem Arzt gestellt werden. Es tut mir Leid, aber ich kann dir leider nicht helfen. Bitte wende dich ein deinen Arzt."
//           }
//         },
//         {
//           "type": "expression",
//           "name": "question4",
//           "visibleIf": "({FrageBzgDiagnose} = 'ja') ",
//           "title": {
//             "de": "Alles klar. Dann werde ich dir einige Fragen zu deinen Beschwerden stellen."
//           }
//         },
//         {
//           "type": "panel",
//           "name": "panel3",
//           "elements": [
//             {
//               "type": "text",
//               "name": "firstname",
//               "title": {
//                 "default": "(First)",
//                 "de": "Wie heißt du eigentlich?"
//               },
//               "titleLocation": "top",
//               "autocomplete": "name"
//             },
//             {
//               "type": "text",
//               "name": "birthDate",
//               "title": {
//                 "default": "Date of birth:",
//                 "de": "Wie alt bist du, {firstname}?"
//               },
//               "titleLocation": "top",
//               "inputType": "date",
//               "max": "2002-01-01"
//             }
//           ],
//           "visibleIf": "({FrageBzgDiagnose} = 'ja') ",
//           "questionTitleLocation": "bottom",
//           "title": {
//             "default": "Patient Name",
//             "de": "Lass uns kennenlernen"
//           }
//         },
//         {
//           "type": "panel",
//           "name": "panel1",
//           "elements": [
//             {
//               "type": "radiogroup",
//               "name": "completedBy",
//               "title": {
//                 "default": "Who completed this form:",
//                 "de": "Wer füllt dieses Formular aus?"
//               },
//               "choices": [
//                 {
//                   "value": "patient",
//                   "text": {
//                     "default": "Patient",
//                     "de": "{firstname}"
//                   }
//                 },
//                 {
//                   "value": "other",
//                   "text": {
//                     "de": "Eine andere Person"
//                   }
//                 }
//               ],
//               "otherText": {
//                 "default": "Other (specify)",
//                 "de": "Sonstiges (Bitte angeben)"
//               },
//               "colCount": 0
//             },
//             {
//               "type": "text",
//               "name": "completedByOtherName",
//               "visibleIf": "{completedBy} = 'other'",
//               "startWithNewLine": false,
//               "title": {
//                 "default": "Name (if other than patient):",
//                 "de": "wie ist der Name dieser Person?"
//               },
//               "isRequired": true
//             }
//           ],
//           "visibleIf": "({FrageBzgDiagnose} = 'ja') ",
//           "title": {
//             "default": "Completed By",
//             "de": "Abgeschlossen von"
//           }
//         }
//       ],
//       "title": "Einleitung"
//     },
//     {
//       "name": "medicalHistory",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "everhospitalized",
//           "title": {
//             "default": "Have you ever been hospitalized?",
//             "de": "Hattest du Schübe?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "injuriesbrokenbones",
//           "title": {
//             "default": "Have you had any serious injuries and/or broken bones?",
//             "de": "Hast du Kortison bekommen?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "comment",
//           "name": "injuriesbrokenbones_describe",
//           "visible": false,
//           "visibleIf": "{injuriesbrokenbones} = \"yes\"",
//           "startWithNewLine": false,
//           "title": "Describe",
//           "isRequired": true
//         },
//         {
//           "type": "radiogroup",
//           "name": "question9",
//           "title": {
//             "default": "Have you ever received a blood transfusion?",
//             "de": "Wurdest du in einem Krankenhaus behandelt?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "text",
//           "name": "bloodtransfusion_years",
//           "visible": false,
//           "visibleIf": "{bloodtransfusion}=\"yes\"",
//           "startWithNewLine": false,
//           "title": {
//             "default": "Approximate year(s)",
//             "de": "Wann und wo?"
//           }
//         },
//         {
//           "type": "radiogroup",
//           "name": "ousideUSACanada",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Wurde bei dir ein MRT durchgeführt?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "rating",
//           "name": "question1",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hat sich dein Zustand"
//           },
//           "rateValues": [
//             {
//               "value": "no",
//               "text": {
//                 "default": "No",
//                 "de": "gebessert"
//               }
//             },
//             {
//               "value": "yes",
//               "text": {
//                 "default": "Yes",
//                 "de": "ist gleich geblieben"
//               }
//             },
//             {
//               "value": "item1",
//               "text": {
//                 "de": "verschlechtert"
//               }
//             },
//             {
//               "value": "item2",
//               "text": {
//                 "de": "weiß ich nicht"
//               }
//             }
//           ]
//         },
//         {
//           "type": "radiogroup",
//           "name": "question2",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast du Schwierigkeiten beim Gehen?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question6",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast Du Schwierigkeiten beim Treppensteigen?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question7",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast Du Probleme mit den Händen, z. B.beim Schreiben oder zuknöpfen der Kleidung?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "bloodtransfusion",
//           "title": {
//             "default": "Have you ever received a blood transfusion?",
//             "de": "Fühlst du Schwäche in bestimmten Körperteilen?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "text",
//           "name": "question8",
//           "visible": false,
//           "visibleIf": "{bloodtransfusion}=\"yes\"",
//           "startWithNewLine": false,
//           "title": {
//             "default": "Approximate year(s)",
//             "de": "Wo?"
//           }
//         },
//         {
//           "type": "radiogroup",
//           "name": "question10",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast Du Probleme bei anderen Bewegungen, außer beim Gehen, Treppensteigen oder in den Händen?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question11",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast du Probleme mit Gleichgewicht / Koordination?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question12",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast du Probleme mit Gleichgewicht / Koordination?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question13",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Zittert Dein Körper?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question14",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast Du Spastik, eine Versteifung der Muskeln,die schmerzhaft sein kann und nachts auftreten kann? wenn ja, wo?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         }
//       ],
//       "questionTitleLocation": "left",
//       "title": {
//         "default": "Past Medical History",
//         "de": "Erzähl mir {firstname} seit dem letzten Arzttermin"
//       }
//     },
//     {
//       "name": "page1",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question15",
//           "title": {
//             "default": "Have you ever been hospitalized?",
//             "de": "Spürst Du Kribbeln und Taubheitsgefühl? wenn ja, wo?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question16",
//           "title": {
//             "default": "Have you had any serious injuries and/or broken bones?",
//             "de": "Fühlst Du Dich müde und erschöpft?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "comment",
//           "name": "question17",
//           "visible": false,
//           "visibleIf": "{question16} = \"yes\"",
//           "startWithNewLine": false,
//           "title": "Describe",
//           "isRequired": true
//         },
//         {
//           "type": "radiogroup",
//           "name": "question18",
//           "title": {
//             "default": "Have you ever received a blood transfusion?",
//             "de": "Hast Du Schlafprobleme?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "text",
//           "name": "question19",
//           "visible": false,
//           "visibleIf": "{question25}=\"yes\"",
//           "startWithNewLine": false,
//           "title": {
//             "default": "Approximate year(s)",
//             "de": "Wann und wo?"
//           }
//         },
//         {
//           "type": "radiogroup",
//           "name": "question20",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast Du Schwierigkeiten, dich zu konzentrieren, dir die Dinge zu merken oder mehrere Aufgaben gleichzeitig zu machen?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question32",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast Du Probleme beim Sprechen, Trinken oder Essen? ja"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question22",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast du Schmerzen?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question23",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast Du Dich hoffnungslos und traurig gefühlt?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question24",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast Du Probleme beim Urin lassen? Z. B. wenn DuUrin nicht halten kannst, öfters auf die Toilette musst oder nicht richtig Urin lassen kannst?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question25",
//           "title": {
//             "default": "Have you ever received a blood transfusion?",
//             "de": "Hast Du Probleme mit dem Stuhlgang?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "text",
//           "name": "question26",
//           "visible": false,
//           "visibleIf": "{question25}=\"yes\"",
//           "startWithNewLine": false,
//           "title": {
//             "default": "Approximate year(s)",
//             "de": "Wo?"
//           }
//         },
//         {
//           "type": "radiogroup",
//           "name": "question27",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hat sich Dein Sexualleben verändert oder ist etwas beim Geschlechtsverkehr anders geworden?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question28",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast Du Probleme beim Sehen?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question29",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Ist Dir aufgefallen, dass etwas mit Deinen Augen- bewegungen nicht stimmt oder Deine Augen zittern?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question30",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Hast Du andere Beschwerden, die nur kurz andauern und wieder verschwinden? Wenn ja, welche und in welchen Situation treten sie auf?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         }
//       ],
//       "questionTitleLocation": "left",
//       "title": {
//         "default": "Past Medical History",
//         "de": "Erzähl mir {firstname} seit dem letzten Arzttermin"
//       }
//     },
//     {
//       "name": "page2",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question21",
//           "title": {
//             "default": "Have you ever been hospitalized?",
//             "de": "Ist dir sonst noch etwas ungewöhnliches aufgefallen? Wenn ja, was?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "radiogroup",
//           "name": "question31",
//           "title": {
//             "default": "Have you had any serious injuries and/or broken bones?",
//             "de": "Bist du mit deinem Medikament bzw. Medikamenten zufrieden?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "comment",
//           "name": "question33",
//           "visible": false,
//           "visibleIf": "{question31} = \"yes\"",
//           "startWithNewLine": false,
//           "title": "Describe"
//         },
//         {
//           "type": "radiogroup",
//           "name": "question34",
//           "title": {
//             "default": "Have you ever received a blood transfusion?",
//             "de": "Hast du Nebenwirkungen von den Medikamenten? wenn ja, welche?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         },
//         {
//           "type": "text",
//           "name": "question35",
//           "visible": false,
//           "startWithNewLine": false,
//           "title": {
//             "default": "Approximate year(s)",
//             "de": "Wann und wo?"
//           }
//         },
//         {
//           "type": "radiogroup",
//           "name": "question36",
//           "title": {
//             "default": "Have you ever traveled or lived outside the United States or Canada?",
//             "de": "Nimmst du deine Medikamente regelmäßig ein?"
//           },
//           "choices": [
//             {
//               "value": "no",
//               "text": "No"
//             },
//             {
//               "value": "yes",
//               "text": "Yes"
//             }
//           ],
//           "colCount": 0
//         }
//       ],
//       "questionTitleLocation": "left",
//       "title": {
//         "default": "Past Medical History",
//         "de": "Erzähl mir {firstname} seit dem letzten Arzttermin"
//       }
//     }
//   ],
//   "sendResultOnPageNext": true,
//   "showQuestionNumbers": "off",
//   "showProgressBar": "top"
// };


// const json = {
//   "locale": "de",
//   "logoPosition": "right",
//   "completedHtml": {
//     "de": "<h3>Schön, dass du dir die Zeit genommen hast, die Fragen zu beantworten. Es gibt einige Beschwerden, die wir noch genauer anschauen sollen. Anhand deiner Antworten schlage ich vor, dass du folgende Fragen beantwortest und den Fragebogen beim nächsten Termin deinem Arzt mitbringst. Legen wir direkt los!</h3>"
//   },
//   "completedBeforeHtml": {
//     "de": "<h3>Aus unseren Aufzeichnungen geht hervor, dass Sie diese Umfrage bereits ausgefüllt haben.</h3>"
//   },
//   "pages": [
//     {
//       "name": "page1",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question1",
//           "title": "Hattest du Schübe?",
//           "choices": [
//             {
//               "value": "Item 1",
//               "text": {
//                 "de": "Ja"
//               }
//             },
//             {
//               "value": "Item 2",
//               "text": {
//                 "default": "Ja1",
//                 "de": "Nein"
//               }
//             }
//           ],
//           "separateSpecialChoices": true,
//           "colCount": 0
//         }
//       ]
//     },
//     {
//       "name": "page2",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question2",
//           "title": "Hast du Kortison bekommen?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             {
//               "value": "Item 1",
//               "text": {
//                 "de": "Ja"
//               }
//             },
//             {
//               "value": "Item 2",
//               "text": {
//                 "de": "-"
//               }
//             },
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page3",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question3",
//           "title": "Wurdest du in einem Krankenhaus behandelt?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page4",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question4",
//           "title": "Wurde bei dir ein MRT durchgeführt?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page5",
//       "elements": [
//         {
//           "type": "rating",
//           "name": "question5",
//           "title": "Hat sich dein Zustand",
//           "rateValues": [
//             {
//               "value": "Item 1",
//               "text": "gebessert"
//             },
//             {
//               "value": "Item 2",
//               "text": "ist gleich geblieben"
//             },
//             {
//               "value": "Item 3",
//               "text": "verschlechtert"
//             },
//             {
//               "value": "Item 4",
//               "text": "weiß ich nicht"
//             }
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page6",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question6",
//           "title": "Hast du Schwierigkeiten beim Gehen?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page7",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question7",
//           "title": "Hast Du Schwierigkeiten beim Treppensteigen?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page8",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question8",
//           "title": "Hast Du Probleme mit den Händen, z. B.beim Schreiben oder zuknöpfen der Kleidung?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page9",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question9",
//           "title": "Fühlst du Schwäche in bestimmten Körperteilen?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page10",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question10",
//           "title": "Hast Du Probleme bei anderen Bewegungen, außer beim Gehen, Treppensteigen oder in den Händen?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page11",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question11",
//           "title": "Hast du Probleme mit Gleichgewicht / Koordination?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page12",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question12",
//           "title": "Hast du Probleme mit Gleichgewicht / Koordination?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page13",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question13",
//           "title": "Zittert Dein Körper?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page14",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question14",
//           "title": "Hast Du Spastik, eine Versteifung der Muskeln,die schmerzhaft sein kann und nachts auftreten kann? wenn ja, wo?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page15",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question15",
//           "title": "Spürst Du Kribbeln und Taubheitsgefühl? wenn ja, wo?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page16",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question16",
//           "title": "Fühlst Du Dich müde und erschöpft?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page17",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question17",
//           "title": "Hast Du Schlafprobleme?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page18",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question18",
//           "title": "Hast Du Schwierigkeiten, dich zu konzentrieren, dir die Dinge zu merken oder mehrere Aufgaben gleichzeitig zu machen?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page19",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question19",
//           "title": "Hast Du Probleme beim Sprechen, Trinken oder Essen?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page20",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question20",
//           "title": "Hast du Schmerzen?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page21",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question21",
//           "title": "Hast Du Dich hoffnungslos und traurig gefühlt?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page22",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question22",
//           "title": "Hast Du Probleme beim Urin lassen? Z. B. wenn DuUrin nicht halten kannst, öfters auf die Toilette musst oder nicht richtig Urin lassen kannst?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page23",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question23",
//           "title": "Hast Du Probleme mit dem Stuhlgang?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page24",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question24",
//           "title": "Hat sich Dein Sexualleben verändert oder ist etwas beim Geschlechtsverkehr anders geworden?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page25",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question25",
//           "title": "Hast Du Probleme beim Sehen?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page26",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question26",
//           "title": "Ist Dir aufgefallen, dass etwas mit Deinen Augen- bewegungen nicht stimmt oder Deine Augen zittern?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page27",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question27",
//           "title": "Hast Du andere Beschwerden, die nur kurz andauern und wieder verschwinden? Wenn ja, welche und in welchen Situation treten sie auf?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page28",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question28",
//           "title": "Ist dir sonst noch etwas ungewöhnliches aufgefallen? Wenn ja, was?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page29",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question29",
//           "title": "Bist du mit deinem Medikament bzw. Medikamenten zufrieden?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page30",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question30",
//           "title": "Hast du Nebenwirkungen von den Medikamenten? wenn ja, welche?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     },
//     {
//       "name": "page31",
//       "elements": [
//         {
//           "type": "radiogroup",
//           "name": "question31",
//           "title": "Nimmst du deine Medikamente regelmäßig ein?",
//           "choicesFromQuestion": "question1",
//           "choices": [
//             "Item 1",
//             "Item 2",
//             "Item 3"
//           ]
//         }
//       ]
//     }
//   ],
//   "sendResultOnPageNext": true,
//   "questionTitleLocation": "left",
//   "showProgressBar": "top",
//   "progressBarType": "questions",
//   "goNextPageAutomatic": true,
//   //"showQuestionNumbers": "off",
//   "pagePrevText": {
//     "de": "Vorherige Frage"
//   },
//   "pageNextText": {
//     "de": "Nächste Frage"
//   },
//   "completeText": {
//     "de": "Abschließen"
//   },
//   "questionsOnPageMode": "questionPerPage",
//   "showPreviewBeforeComplete": "showAllQuestions",
//   "widthMode": "responsive"
// }


const json = {
  "locale": "de",
  "logoPosition": "right",
  "completedHtml": {
    "de": "<h3>Schön, dass du dir die Zeit genommen hast, die Fragen zu beantworten. Es gibt einige Beschwerden, die wir noch genauer anschauen sollen. Anhand deiner Antworten schlage ich vor, dass du folgende Fragen beantwortest und den Fragebogen beim nächsten Termin deinem Arzt mitbringst. Legen wir direkt los!</h3>"
  },
  "completedBeforeHtml": {
    "de": "<h3>Aus unseren Aufzeichnungen geht hervor, dass Sie diese Umfrage bereits ausgefüllt haben.</h3>"
  },
  "pages": [
    {
      "name": "page1",
      "elements": [
        {
          "type": "radiogroup",
          "name": "first q",
          "minWidth": "100%",
          "title": {
            "default": "Eine wichtige Frage vorab: hat ein Arzt bei dir die Diagnose Multiple Sklerose (MS) gestellt?",
            "de": "Eine wichtige Frage vorab: hat ein Arzt bei dir die Diagnose Multiple Sklerose (MS) gestellt?"
          },
          "isRequired": true,
          "choices": [
            {
              "value": "ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "nein",
              "text": {
                "default": "Nein",
                "de": "Nein"
              }
            },
            {
              "value": "iwn",
              "text": {
                "default": "Ich weiß nicht",
                "de": "Ich weiß nicht"
              }
            }
          ],
          "separateSpecialChoices": true,
          "colCount": 0
        },
        {
          "type": "html",
          "name": "question33",
          "visibleIf": "{first q} = 'iwn' or {first q} = 'nein'",
          "html": {
            "de": "<p>Eine Diagnose kann nur von einem Arzt gestellt werden. Es tur mir Leid, aber ich kann dir leider nicht helfen.</p>\n\n<h4>Bitte wenden Sie sich an Ihren Arzt</h4>"
          }
        }
      ]
    },
    {
      "name": "page32",
      "elements": [
        {
          "type": "panel",
          "name": "panel1",
          "elements": [
            {
              "type": "html",
              "name": "question34",
              "html": {
                "de": "<p style='margin-top:2rem'>Alles klar. Dann werde ich dir einige Fragen zu deinen Beschwerden stellen.</p>"
              }
            },
            {
              "type": "html",
              "name": "question35",
              "html": {
                "de": "<p><span style=\"color:red; font-weight:bold\">Wichtige Informationen vorab</span>: deine Daten werden bei uns nicht gespeichert. Bitte speichere sie selbst, damit du sie ausducken und deinem Arzt zeigen kannst.</p>"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question1",
          "minWidth": "100%",
          "title": {
            "default": "Welche Form der MS hast Du?",
            "de": "Welche Form der MS hast Du?"
          },
          "choices": [
            {
              "value": "Item 1",
              "text": {
                "de": "PRMS"
              }
            },
            {
              "value": "Item 2",
              "text": {
                "default": "SPMS",
                "de": "SPMS"
              }
            },
            {
              "value": "Item 3",
              "text": {
                "default": "PPMS",
                "de": "PPMS"
              }
            },
            {
              "value": "Item 4",
              "text": {
                "default": "Ich weiß nicht",
                "de": "Ich weiß nicht"
              }
            }
          ],
          "separateSpecialChoices": true,
          "showOtherItem": false,
          "colCount": 0
        },
        {
          "type": "radiogroup",
          "name": "question32",
          "minWidth": "100%",
          "title": "Hattest du Schübe?",
          "choices": [
            {
              "value": "Item 1",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Item 2",
              "text": {
                "default": "Ja1",
                "de": "Nein"
              }
            }
          ],
          "separateSpecialChoices": true,
          "colCount": 0
        },
        {
          "type": "radiogroup",
          "name": "question2",
          "title": "Hast du Kortison bekommen?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "panel",
          "name": "panel2",
          "elements": [
            {
              "type": "radiogroup",
              "name": "05krankenhaus",
              "title": {
                "default": "Wurdest du in einem Krankenhaus behandelt?",
                "de": "Wurdest du in einem Krankenhaus behandelt? wenn ja, wann und wo?"
              },
              "choices": [
                "Ja",
                "Nein"
              ]
            },
            {
              "type": "multipletext",
              "name": "question36",
              "visibleIf": "{05krankenhaus} = 'Ja'",
              "title": {
                "de": "wann und wo"
              },
              "items": [
                {
                  "name": "text1",
                  "title": {
                    "de": "Wann"
                  }
                },
                {
                  "name": "text2",
                  "title": {
                    "de": "Wo"
                  }
                }
              ]
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question4",
          "title": "Wurde bei dir ein MRT durchgeführt?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "rating",
          "name": "question5",
          "title": "Hat sich dein Zustand",
          "rateValues": [
            {
              "value": "Item 1",
              "text": "gebessert"
            },
            {
              "value": "Item 2",
              "text": "ist gleich geblieben"
            },
            {
              "value": "Item 3",
              "text": "verschlechtert"
            },
            {
              "value": "Item 4",
              "text": "Weiß ich nicht"
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question6",
          "title": "Hast du Schwierigkeiten beim Gehen?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question7",
          "title": "Hast Du Schwierigkeiten beim Treppensteigen?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question8",
          "title": "Hast Du Probleme mit den Händen, z. B.beim Schreiben oder zuknöpfen der Kleidung?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "panel",
          "name": "panel3",
          "elements": [
            {
              "type": "radiogroup",
              "name": "12Körperteil",
              "title": {
                "default": "Fühlst du Schwäche in bestimmten Körperteilen?",
                "de": "Fühlst du Schwäche in bestimmten Körperteilen? wenn ja, wo"
              },
              "choices": [
                {
                  "value": "Ja",
                  "text": {
                    "de": "Ja"
                  }
                },
                {
                  "value": "Nein",
                  "text": {
                    "de": "Nein"
                  }
                }
              ]
            },
            {
              "type": "text",
              "name": "question3",
              "visibleIf": "{12Körperteil} = 'Ja'",
              "title": {
                "de": "Wo"
              }
            }
          ]
        },
        {
          "type": "panel",
          "name": "panel4",
          "elements": [
            {
              "type": "radiogroup",
              "name": "14Bewegung",
              "title": {
                "default": "Hast Du Probleme bei anderen Bewegungen, außer beim Gehen, Treppensteigen oder in den Händen?",
                "de": "Hast Du Probleme bei anderen Bewegungen, außer beim Gehen, Treppensteigen oder in den Händen? wenn ja, wo?"
              },
              "choices": [
                "Ja",
                "Nein"
              ]
            },
            {
              "type": "text",
              "name": "question9",
              "visibleIf": "{14Bewegung} = 'Ja'",
              "title": {
                "de": "Wo"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question12",
          "title": "Hast du Probleme mit Gleichgewicht / Koordination?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question13",
          "title": "Zittert Dein Körper?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "panel",
          "name": "panel5",
          "elements": [
            {
              "type": "radiogroup",
              "name": "19spastik",
              "title": {
                "default": "Hast Du Spastik, eine Versteifung der Muskeln,die schmerzhaft sein kann und nachts auftreten kann? wenn ja, wo?",
                "de": "Hast Du Spastik, eine Versteifung der Muskeln, die schmerzhaft sein kann und nachts auftreten kann? wenn ja, wo?"
              },
              "choices": [
                "Ja",
                "Nein "
              ]
            },
            {
              "type": "text",
              "name": "question10",
              "visibleIf": "{19spastik} = 'Ja'",
              "title": {
                "de": "Wo"
              }
            }
          ]
        },
        {
          "type": "panel",
          "name": "panel6",
          "elements": [
            {
              "type": "radiogroup",
              "name": "21Kribbeln",
              "title": "Spürst Du Kribbeln und Taubheitsgefühl? wenn ja, wo?",
              "choices": [
                "Ja",
                "Nein"
              ]
            },
            {
              "type": "text",
              "name": "question14",
              "visibleIf": "{21Kribbeln} = 'Ja'",
              "title": {
                "de": "Wo"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question16",
          "title": "Fühlst Du Dich müde und erschöpft?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question17",
          "title": "Hast Du Schlafprobleme?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question18",
          "title": "Hast Du Schwierigkeiten, dich zu konzentrieren, dir die Dinge zu merken oder mehrere Aufgaben gleichzeitig zu machen?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question19",
          "title": "Hast Du Probleme beim Sprechen, Trinken oder Essen?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "panel",
          "name": "panel7",
          "elements": [
            {
              "type": "radiogroup",
              "name": "27schmerzen",
              "title": {
                "default": "Hast du Schmerzen?",
                "de": "Hast du Schmerzen? wenn ja, wo?"
              },
              "choices": [
                "Ja",
                "Nein"
              ]
            },
            {
              "type": "text",
              "name": "question15",
              "visibleIf": "{27schmerzen} = 'Ja'",
              "title": {
                "de": "Wo"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question21",
          "title": "Hast Du Dich hoffnungslos und traurig gefühlt?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question22",
          "title": {
            "default": "Hast Du Probleme beim Urin lassen? Z. B. wenn DuUrin nicht halten kannst, öfters auf die Toilette musst oder nicht richtig Urin lassen kannst?",
            "de": "Hast Du Probleme beim Urin lassen? Z. B. wenn Du Urin nicht halten kannst, öfters auf die Toilette musst oder nicht richtig Urin lassen kannst?"
          },

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question23",
          "title": "Hast Du Probleme mit dem Stuhlgang?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question24",
          "title": "Hat sich Dein Sexualleben verändert oder ist etwas beim Geschlechtsverkehr anders geworden?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question25",
          "title": "Hast Du Probleme beim Sehen?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question26",
          "title": "Ist Dir aufgefallen, dass etwas mit Deinen Augen- bewegungen nicht stimmt oder Deine Augen zittern?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "panel",
          "name": "panel8",
          "elements": [
            {
              "type": "radiogroup",
              "name": "35Beschwerden",
              "title": "Hast Du andere Beschwerden, die nur kurz andauern und wieder verschwinden? Wenn ja, welche und in welchen Situation treten sie auf?",
              "choices": [
                "nein",
                "ja"
              ],
              "otherPlaceholder": {
                "de": "welche und in welchen Situation treten sie auf"
              },
              "otherText": {
                "de": "Ja"
              }
            },
            {
              "type": "text",
              "name": "question20",
              "visibleIf": "{35Beschwerden} = 'ja'",
              "title": {
                "de": "Welche und in welchen Situation treten sie auf?"
              }
            }
          ]
        },
        {
          "type": "panel",
          "name": "panel9",
          "elements": [
            {
              "type": "radiogroup",
              "name": "37ungewöhnlich",
              "title": "Ist dir sonst noch etwas ungewöhnliches aufgefallen? Wenn ja, was?",
              "choices": [
                "Ja",
                "Nein"
              ]
            },
            {
              "type": "text",
              "name": "question27",
              "visibleIf": "{37ungewöhnlich} = 'Ja'",
              "title": {
                "de": "Was"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question29",
          "title": "Bist du mit deinem Medikament bzw. Medikamenten zufrieden?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        },
        {
          "type": "panel",
          "name": "panel10",
          "elements": [
            {
              "type": "radiogroup",
              "name": "40Nebenwirkungen",
              "title": "Hast du Nebenwirkungen von den Medikamenten? wenn ja, welche?",
              "choices": [
                "Ja",
                "Nein"
              ]
            },
            {
              "type": "text",
              "name": "question28",
              "visibleIf": "{40Nebenwirkungen} = 'Ja'",
              "title": {
                "de": "Welche"
              }
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question31",
          "title": "Nimmst du deine Medikamente regelmäßig ein?",

          "choices": [
            {
              "value": "Ja",
              "text": {
                "de": "Ja"
              }
            },
            {
              "value": "Nein",
              "text": {
                "de": "Nein"
              }
            }
          ]
        }
      ],
      "visibleIf": "{first q} = 'ja'"
    }
  ],
  "sendResultOnPageNext": true,
  "questionTitleLocation": "left",
  "showProgressBar": "top",
  "progressBarType": "questions",
  "goNextPageAutomatic": true,
  "pagePrevText": {
    "de": "Vorherige Frage"
  },
  "pageNextText": {
    "de": "Nächste Frage"
  },
  "completeText": {
    "de": "Abschließen"
  },
  "questionsOnPageMode": "questionPerPage",
  "showPreviewBeforeComplete": "showAllQuestions",
  "widthMode": "responsive"
}

export default json;
