import React, { useState, useEffect } from 'react'
import SidaCharakter from '../SidaCharakter'
import PrimaryButton from '../../components/PrimaryButton'

const SidaMessage = ({ text, typeEffect = false, small = false, btn = false, bG = "", clr = "", charW }) => {
    const [displayedContent, setDisplayedContent] = useState("");
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const animKey = setInterval(() => {
            setIndex((index) => {
                if (index >= text.length - 1) {
                    clearInterval(animKey);
                    return index;
                }
                return index + 1;
            });
        }, 100);
    }, []);



    useEffect(() => {
        setDisplayedContent(
            (displayedContent) => displayedContent + text[index]
        );
    }, [index]);


    return (
        !small ?
            <div className='flex items-start gap-5'>
                <div >
                    <SidaCharakter height={charW} width={charW} />
                </div>
                <div className="flex flex-col gap-2">
                    <h3 className="text-lg font-semibold font-typeFont">Sida</h3>
                    <div className='border rounded-tl-none rounded-2xl  flex-grow p-4'>
                        <p className={`text-base ${typeEffect && "type-writer"} whitespace-pre-line font-typeFont`}>
                            {text}
                        </p>
                    </div>
                </div>
            </div>
            : <>
                <div className="flex gap-1">
                    <div >
                        <SidaCharakter height={charW} width={charW} />
                    </div>
                    <div className="flex items-start flex-col gap-2">
                        <h3 className="text-lg font-semibold font-typeFont">Sida</h3>
                        <div className={`border rounded-tl-none rounded-2xl ${bG} flex items-center justify-center ${clr} p-3`}>
                            <p className={`text-base ${typeEffect && "type-writer"} font-typeFont`}>{typeEffect ? displayedContent : text}</p>
                        </div>
                        {btn && <PrimaryButton text={"Chat with Sida"} to={"sida"} typ="dark" />}
                    </div>

                </div>
            </>
    )
}

export default SidaMessage