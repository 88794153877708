import React from 'react'
import { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";

import AppSidebar from './AppSidebar'
import Loader from '../tools/Loader';


const AppLayout = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            await new Promise((r) => setTimeout(r, 2000));
            setLoading((loading) => !loading);
        };

        loadData();
    }, [])


    if (loading) {
        return <Loader />
    } else {
        return (
            <div className='mainContainer'>
                <div className='grid-area-sidebar bg-DarkBg'>
                    <AppSidebar />
                </div>
                <div className='grid-area-main bg-primaryBG  '>
                    <Outlet />
                </div>
            </div>
        )
    }

}

export default AppLayout