import React from "react";
import SidaCharakter from "../SidaCharakter"
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const faqs = [
  {
    title: "Stet clita kasd gubergren?",
    txt: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
  }, {
    title: "Takimata sanctus est?",
    txt: "Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, At accusam aliquyam diam diam dolore dolores duo eirmod eos erat, et nonumy sed tempor et et invidunt justo labore.    ",
  }, {
    title: "Ea commodo consequat?",
    txt: "Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
  }, {
    title: "Ed diam nonummy nibh?",
    txt: "Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
  }
]
const Footer = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="py-2.5 w-full border-b border-borderColorOnDarkBG bg-DarkBg text-white">
        <div className="py-28 container mx-auto flex flex-col gap-5 items-center ">
          <div className="text-center">
            <h2 className="mb-4 text-4xl sm:text-6xl font-extrabold leading-none tracking-tight">Accusam et justo</h2>
            <p className="text-xl"></p>
          </div>

          <div className="lg:px-60">
            {faqs?.map((faq, i) => {
              return (
                <div key={i} className="border-b border-borderColorOnDarkBG py-5">
                  <h4 className="mb-4 text-xl md:text-2xl  font-extrabold leading-none tracking-tight font-typeFont">{faq.title}</h4>
                  <p className="text-gray-50 text-sm">{faq.txt}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <footer className="bg-DarkBg px-2 sm:px-4 py-2.5 w-full text-white">
        <div className="container mt-8 flex flex-wrap items-center justify-between mx-auto flex-col md:flex-row">
          <a href="/" className="flex items-center mb-4 gap-3 sm:mb-0">
            <SidaCharakter height="40" width="40" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white font-typeFont">SIDA</span>
          </a>
          <ul className="text-lg flex gap-5 justify-between">
            <li>
              <button className="md:p-4 py-2 block hover:text-primaryColor " onClick={() => { navigate("/impressum") }}>Impressum</button>
            </li>
            <li>
              <button className="md:p-4 py-2 block hover:text-primaryColor " onClick={() => { navigate("/datenschutz") }}>Datenschutz</button>
            </li>
          </ul>
        </div>
        <hr className="my-6 border border-borderColorOnDarkBG sm:mx-auto lg:my-8" />
        <span className="block text-sm  text-center"><a href="/" className="hover:underline">Sida</a>. Alle Rechte vorbehalten.
        </span>
      </footer>
    </>
  );
};

export default Footer;
