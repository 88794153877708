import { Link, NavLink } from "react-router-dom";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faHouse, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'

const Sidebar = () => {

  return (
    <div className="flex justify-around md:flex-col items-center gap-10 h-full py-6  relative z-10">
      <div className="text-lg md:text-2xl font-typeFont text-white font-semibold">SIDA</div>

      <nav className="flex md:flex-col justify-center h-full items-center">
        <div className="flex md:flex-col justify-center gap-10 items-start ">
          <NavLink className={"text-white hover:text-blue-500 md:px-14 w-full lg:border-r-4 border-DarkBg"} to="sida">
            <FontAwesomeIcon icon={faHouse} className="text-base md:text-lg " />
            <span className="hidden md:inline pl-4 font-typeFont text-base md:text-lg">Home</span>
          </NavLink>


          <NavLink className={"text-white hover:text-blue-500 md:px-14 w-full border-r-4 border-DarkBg"} to="library">
            <FontAwesomeIcon icon={faList} className="text-base md:text-lg" />
            <span className="hidden md:inline pl-4 font-typeFont text-base md:text-lg">Bibliothek</span>
          </NavLink>

          <Link className="hover:text-red-500 text-white md:px-14 w-full" to="/">
            <FontAwesomeIcon icon={faArrowRightFromBracket} className="text-base md:text-lg fa-rotate-180" />
            <span className="hidden md:inline pl-4 font-typeFont text-base md:text-lg">Abbrechen</span>
          </Link>
        </div>

      </nav>
    </div>
  );
};

export default Sidebar;
