const fatigueJSON = {
    "locale": "de",
    "title": "Fragebogen zu Fatigue",
    "description": "Fragebogen zu Fatigue",
    "logoPosition": "right",
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    "type": "text",
                    "name": "question1",
                    "title": {
                        "de": "test"
                    }
                }
            ],
            "title": {
                "de": "test"
            }
        }
    ],
    "cookieName": "Fragebogen zu Fatigue",
    "widthMode": "responsive"
}

export default fatigueJSON
