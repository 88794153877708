import React from "react";
import "./index.css";

import Datenschutz from "./layout/Pages/Datenschutz";
import Impressum from "./layout/Pages/Impressum";

import json from "./schemas/json";
import depressionsJSON from "./schemas/depressionsJSON"
import fatigueJSON from "./schemas/fatigueJSON"

import AppLayout from "./layout/AppComponents/AppLayout";
import AppIntro from "./layout/AppComponents/AppIntro";

import SurveyCreator from "./SurveyCreator";
import AppLibrary from "./layout/AppComponents/AppLibrary"
import LandingPage from "./layout/Pages/LandingPage";

import NoMatch from "./components/NoMatch";

import { Routes, Route } from "react-router-dom";

const LazyContact = React.lazy(() => import("./layout/Pages/About"))



function App() {

  return (<>
    <div className="h-screen">
      <Routes>
        <Route index element={<LandingPage />} />

        <Route element={<AppLayout />}>
          <Route index path="sida" element={<AppIntro />} />
          <Route path="library" element={<AppLibrary />} />

          <Route path="depressions" element={<SurveyCreator schema={depressionsJSON} />} />
          <Route path="mobility" element={<SurveyCreator schema={json} />} />
          <Route path="fatigue" element={<SurveyCreator schema={fatigueJSON} />} />
        </Route>

        <Route path="/impressum" element={<Impressum />} />
        <Route path="/datenschutz" element={<Datenschutz />} />


        <Route
          path="contact"
          element={
            <React.Suspense fallback="Loading...">
              <LazyContact />
            </React.Suspense>
          }
        />

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  </>
  );
}

export default App;
