import React from "react";


import Navbar from "../Sections/Navbar";
import Header from "../Sections/Header";
import Footer from "../Sections/Footer";

import SidaCharakter from "../SidaCharakter";
import SidaCharOnly from "../SidaCharOnly"
import PrimaryButton from "../../components/PrimaryButton";

const LandingPage = () => {

  return (
    <div >
      <Navbar />
      <Header />


      <div className="py-2.5 w-full border-b border-gray-200 bg-LightBG ">
        <div className="py-28 mx-auto h-full grid md:gap-5 grid-cols-1 lg:grid-cols-3">
          <div className="md:pl-16 container flex flex-col items-center justify-center gap-12">
            <div className="text-center md:text-left">
              <h2 className="mb-4 text-4xl sm:text-6xl font-extrabold leading-none tracking-tight text-gray-900 ">Consetetur sading</h2>
              <p className="mb-4 text-lg sm:text-xl">
                At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no
                sea takimata sanctus est
              </p>
            </div>

            <div className="flex gap-5">
              <div className="flex">
                <h3 className=" text-primaryColor font-semibold text-xl sm:text-2xl font-typeFont">01</h3>
              </div>
              <div className="flex flex-col gap-5">
                <span className="font-semibold text-gray-900 text-xl md:text-2xl font-typeFont">Labore et dolore magna aliquyam erat, sed diam voluptua.</span>
                <span className="block text-base">
                  At vero eos et accusam et justo duo dolores et
                  ea rebum. Stet clita kasd gubergren, no sea
                  takimata sanctus est sed diam nonumy eirmod
                  tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam</span>
              </div>
            </div>

            <div className="flex gap-5">
              <div className="flex">
                <h3 className=" text-primaryColor font-semibold text-xl sm:text-2xl font-typeFont">02</h3>
              </div>
              <div className="flex flex-col gap-5">
                <span className="font-semibold text-gray-900 text-xl sm:text-2xl font-typeFont">Labore et dolore magna aliquyam erat, sed diam voluptua.</span>
                <span className="block text-base">
                  At vero eos et accusam et justo duo dolores et
                  ea rebum. Stet clita kasd gubergren, no sea
                  takimata sanctus est sed diam nonumy eirmod
                  tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam</span>
              </div>
            </div>
          </div>
          <div className="w-full col-span-2 hidden lg:block min-h-75-screen">
            <iframe className="ipad" frameBorder="0" id="frame" title="phone" src="/library" width="100%" height="100%"></iframe>
          </div>
        </div>
      </div>

      {/* 02 */}

      {/* 03 */}
      <div className="bg-primaryBG py-2.5 w-full border-b border-gray-200">
        <div className="py-28 container  mx-auto flex flex-col lg:flex-row gap-10">
          <div className="basis-1/2 text-center md:text-left">
            <h2 className="mb-4 text-4xl sm:text-6xl font-extrabold leading-none tracking-tight text-gray-900">Labore et dolore magna</h2>
            <p className="mb-4 text-lg sm:text-xl">At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus</p>
            <PrimaryButton text={"Chat with Sida"} to={"sida"} typ="dark" />
          </div>

          <div className="basis-1/2 ml-8 md:ml-0">
            <ol className="relative border-l border-gray-200 ">
              <li className="mb-24 ml-8">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                  <svg aria-hidden="true" className="w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                </span>
                <h3 className="leading-tight font-semibold text-xl sm:text-2xl font-typeFont">Labore et dolore magna aliquyam erat</h3>
                <p className="text-base">At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren</p>
              </li>
              <li className="mb-24 ml-8">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                  <svg aria-hidden="true" className="w-5 h-5 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd"></path></svg>
                </span>
                <h3 className="leading-tight font-semibold text-xl sm:text-2xl font-typeFont">Est sed diam nonumy eirmod tempor</h3>
                <p className="text-base">Sea takimata sanctus est sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
              </li>
              <li className="mb-24 ml-8">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                  <svg aria-hidden="true" className="w-5 h-5 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd"></path></svg>
                </span>
                <h3 className="leading-tight font-semibold text-xl sm:text-2xl font-typeFont">Est sed diam nonumy eirmod tempor</h3>
                <p className="text-base">Invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
              </li>
              <li className="ml-8">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                  <svg aria-hidden="true" className="w-5 h-5 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                </span>
                <h3 className="leading-tight font-semibold text-xl sm:text-2xl font-typeFont">Diam nonumy eirmod tempor</h3>
                <p className="text-base">Justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est sed diam</p>
              </li>
            </ol>
          </div>
        </div>
      </div>
      {/* 03 */}

      {/* 04 */}
      <div className="bg-primaryBG py-2.5 w-full border-b border-gray-200">
        <div className="py-28 container mx-auto flex gap-10 flex-col lg:flex-row items-center">
          <div className="basis-1/3 hidden md:block">
            <div className="iphone-x">
              <iframe frameBorder="0" id="frame" title="phone" src="/" width="100%" height="100%"></iframe>
            </div>
          </div>

          <div className="basis-auto">
            <div className="flex flex-col gap-11 basis-full">
              <div className="text-center md:text-left">
                <h2 className="mb-4 text-4xl sm:text-6xl font-extrabold leading-none tracking-tight text-gray-900 ">Quis nostrud exerci tation</h2>
                <p className="text-lg sm:text-xl">Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
              </div>
              <div className="flex flex-row gap-4">
                <div>
                  <h3 className="mb-4 text-xl sm:text-2xl  font-extrabold leading-none tracking-tight text-gray-900 font-typeFont">Dolore magna aliquyam</h3>
                  <hr className="w-14 border-BorderColor"></hr>
                  <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                </div>
                <div>
                  <h3 className="mb-4 text-xl sm:text-2xl font-extrabold leading-none tracking-tight text-gray-900 font-typeFont">Dolore magna aliquyam</h3>
                  <hr className="w-14 border-BorderColor"></hr>
                  <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 04 */}

      {/* 05 */}
      <div className="bg-primaryBG py-2.5 w-full border-b border-gray-200">
        <div className="py-28 container mx-auto flex flex-col gap-5 items-center">
          <div className="text-center">
            <h2 className="mb-4 text-4xl sm:text-6xl font-extrabold leading-none tracking-tight text-gray-900">Invidunt ut labore</h2>
            <p className="text-lg sm:text-xl">quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat</p>
          </div>

          <div className="flex gap-11 flex-col">
            <div className="flex items-center gap-5">
              <div>
                <SidaCharakter height="120" width="120" />
              </div>
              <div className="border rounded-xl rounded-tl-none border-blue-500 p-5">
                <h3 className="mb-4 font-extrabold leading-none tracking-tight text-primaryColor text-lg sm:text-xl font-typeFont">Dolore magna aliquyam</h3>
                <p className="text-base">At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                  no sea takimata sanctus est sed diam nonumy eirmod tempor invidunt ut labore
                  et dolore magna aliquyam erat, sed diam voluptua.</p>
              </div>
            </div>

            <div className="flex items-center gap-5">
              <div className="order-2">
                <SidaCharakter height="120" width="120" />
              </div>
              <div className="border rounded-xl rounded-tl-none border-blue-500 p-5">
                <h2 className="mb-4 font-extrabold leading-none tracking-tight text-primaryColor text-lg sm:text-xl font-typeFont">Dolore magna aliquyam</h2>
                <p className="text-base">At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                  no sea takimata sanctus est sed diam nonumy eirmod tempor invidunt ut labore
                  et dolore magna aliquyam erat, sed diam voluptua.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* 05 */}

      {/* 06 */}
      <div className="bg-LightBG pt-2.5 w-full border-b border-gray-200">
        <div className=" pt-28 container mx-auto flex flex-col gap-10 items-center text-center">
          <div className="text-center flex flex-col md:items-center ">
            <h1 className="mb-4 text-4xl sm:text-6xl font-extrabold leading-none tracking-tight text-gray-900">Nam liber tempor cum soluta</h1>
            <p className="text-lg sm:text-xl mb-3">exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat</p>
            <PrimaryButton text={"Chat with Sida"} to={"sida"} typ="dark" />
          </div>

          <div className="flex gap-11 flex-col">
            <div className="-mb-5px">
              <SidaCharOnly height="380" width="380" />
            </div>
          </div>
        </div>
      </div>
      {/* 06 */}
      <Footer />


    </div >
  );
};

export default LandingPage;
