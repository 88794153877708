import React from 'react';
import Navbar from '../Sections/Navbar';
import Footer from '../Sections/Footer';

export default function Impressum() {

    return (
        <>
            <Navbar />

            <div className="py-2.5 w-full border-b border-gray-200">
                <div className="py-28 mx-auto h-full grid md:gap-5 grid-cols-1">
                    <div className="container">
                        <div className="card">
                            <div className="card-header font-semibold">
                                Impressum
                            </div>
                            <div className='card-body'>
                                <h5 className="card-title font-semibold">Institut für Angewandte Informatik (InfAI)&nbsp;e. V.</h5>
                                <span className='card-text'>
                                    <p>
                                        Goerdelerring 9
                                        <br />
                                        04109 Leipzig
                                    </p>
                                    <p>
                                        Telefon: +49 341 229037 0<br />
                                        Telefax: +49 341 229037 99<br />
                                        E-Mail:&nbsp;<a href="mailto:info@infai.org">info@infai.org</a>
                                    </p>
                                    <p>
                                        Vertretungsberechtigter Vorstand:<br />
                                        Prof. Dr. Bogdan Franczyk (1. Vorsitzender)<br />
                                        Prof. Dr. Erhard Rahm (2. Vorsitzender)<br />
                                        Prof. Dr. André Ludwig<br />
                                        Prof. Dr. Roland Fassauer
                                    </p>
                                    <p>
                                        Vorstandsbeisitzer:<br />
                                        Prof. Dr. Sören Auer<br />
                                        Prof. Dr. Gerhard Heyer<br />
                                        Prof. Dr. Gerik Scheuermann
                                    </p>
                                    <p>
                                        Geschäftsführung:<br />
                                        Andreas Heinecke, Prof. Dr. Roland Fassauer
                                    </p>
                                    <p>
                                        Registereintrag:<br />
                                        Registergericht: Amtsgericht Leipzig<br />
                                        Registernummer: VR 4342
                                    </p>
                                    <p>
                                        Umsatzsteuer-ID:<br />
                                        Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz:<br />
                                        DE274344504
                                    </p>
                                    <br />
                                    <hr />
                                    <br />
                                    <h5 className="card-title font-semibold">InfAI Infinity GmbH</h5>
                                    <p>
                                        Goerdelerring 9<br />
                                        04109 Leipzig
                                    </p>
                                    <p>
                                        Telefon: +49 341 229037 0<br />
                                        Telefax: +49 341 229037 99<br />
                                        E-Mail:&nbsp;<a href="mailto:info@infai.org">info@infai.org</a>
                                    </p>
                                    <p>
                                        Geschäftsführung:&nbsp;<strong>&nbsp;</strong><br />
                                        Ingolf Römer, Andreas Heinecke
                                    </p>
                                    <p>
                                        Registereintrag:<br />
                                        Registergericht: Amtsgericht Leipzig<br />
                                        Registernummer: HRB 41024
                                    </p>
                                    <p>
                                        Umsatzsteuer-ID:<br />
                                        Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz:<br />
                                        DE301550288
                                    </p>

                                    <br />
                                    <hr />
                                    <br />

                                    <h5 className="card-title font-semibold">Hinweis gemäß Online-Streitbeilegungs-Verordnung</h5>
                                    <p>Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die Existenz der Europäischen Online-Streitbeilegungs-Plattform hinzuweisen, die für die Beilegung von Streitigkeiten genutzt werden kann, ohne dass ein Gericht eingeschaltet werden muss. Für die Einrichtung der Plattform ist die Europäische Kommission zuständig. Die Europäische Online-Streitbeilegungs-Plattform ist hier zu finden:&nbsp;<a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>. Unsere E-Mail lautet:&nbsp;<a href="mailto:info@infai.org">info@infai.org</a></p>

                                    <br />
                                    <hr />
                                    <br />

                                    <h5 className="card-title font-semibold">Hinweis gemäß Verbraucherstreitbeilegungsgesetz (VSBG)</h5>
                                    <p>Wir sind nicht bereit und verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                                    <p>§ 1 Warnhinweis zu Inhalten<br />
                                        Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte interner Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Allein durch den Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters.</p>
                                    <p>§ 2 Externe Links<br />
                                        Diese Website enthält Verknüpfungen zu Websites Dritter („externe Links“). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.</p>
                                    <p>§ 3 Urheber- und Leistungsschutzrechte<br />
                                        Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.</p>
                                    <p>Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.</p>
                                    <p>§ 4 Besondere Nutzungsbedingungen<br />
                                        Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.</p>
                                    <p>Quelle:&nbsp;<a href="http://www.impressum-recht.de/">www.impressum-recht.de</a></p>
                                    <br />
                                    <hr />
                                    <br />
                                    <h5 className="card-title font-semibold">Disclaimer – rechtliche Hinweise</h5>
                                    <p>§ 1 Warnhinweis zu Inhalten<br />
                                        Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte interner Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Allein durch den Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters.</p>
                                    <p>§ 2 Externe Links<br />
                                        Diese Website enthält Verknüpfungen zu Websites Dritter („externe Links“). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.</p>
                                    <p>§ 3 Urheber- und Leistungsschutzrechte<br />
                                        Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.</p>
                                    <p>Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.</p>
                                    <p>§ 4 Besondere Nutzungsbedingungen<br />
                                        Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.</p>
                                    <p>Quelle:&nbsp;<a href="http://www.impressum-recht.de/">www.impressum-recht.de</a></p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}