import React, { useState } from 'react'
import PrimaryButton from '../../components/PrimaryButton'

const Navbar = () => {
    const [toggle, setToggle] = useState(true);
    return (
        // <nav className="bg-primaryBG px-2 sm:px-4 py-2.5 w-full border-b border-gray-200">
        //     <div className="container flex flex-wrap items-center justify-between mx-auto">
        //         <a href="/" className="flex items-center">
        //             <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">SIDA</span>
        //         </a>
        //         <div className="flex ">
        //             <div className="flex md:order-2 items-center">
        //                 <button data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center p-2  text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
        //                     <span className="sr-only">Open main menu</span>
        //                     <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
        //                 </button>
        //             </div>
        //             <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
        //                 <ul className="flex items-center flex-col p-4 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:font-medium md:border-0 ">
        //                     <li>
        //                         <a href="/" className="block py-2 pl-3 pr-4 text-white bg-primaryColor rounded md:bg-transparent md:text-primaryColor md:p-0 dark:text-white" aria-current="page">Sida</a>
        //                     </li>
        //                     <li>
        //                         <a href="/" className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primaryColor md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Ipsum</a>
        //                     </li>
        //                     <li>
        //                         <a href="/" className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primaryColor md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Elitr</a>
        //                     </li>
        //                     <li>
        //                         <a href="/" className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primaryColor md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Eirmod</a>
        //                     </li>
        //                     <li>
        //                         <a href="/" className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primaryColor md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Magna</a>
        //                     </li>
        //                     <li>
        //                         <PrimaryButton text={"Chat with Sida"} to={"sida"} />
        //                     </li>
        //                 </ul>
        //             </div>
        //         </div>
        //     </div>
        // </nav>
        <header>
            <nav className="flex flex-wrap items-center justify-between w-full  py-2.5  text-lg bg-white absolute border-b border-gray-200">
                <div className="container flex flex-wrap items-center justify-between mx-auto">
                    <div>
                        <a href="/" className="flex items-center">
                            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white font-typeFont">SIDA</span>
                        </a>
                    </div>

                    <svg
                        onClick={() => { setToggle(!toggle) }}
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 cursor-pointer md:hidden block hover:text-primaryColor"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>

                    <div className={`${toggle ? "hidden" : ""} w-full md:flex md:items-center md:w-auto`}>
                        <ul className="pt-4 text-base md:flex md:justify-between md:pt-0" >
                            <li>
                                <a className="md:p-4 py-2 block hover:text-primaryColor border-b md:border-0" href="/">Sida</a>
                            </li>
                            <li>
                                <a className="md:p-4 py-2 block hover:text-primaryColor border-b md:border-0" href="/">Ipsum</a>
                            </li>
                            <li>
                                <a className="md:p-4 py-2 block hover:text-primaryColor border-b md:border-0" href="/">Elitr</a>
                            </li>
                            <li className=''>
                                <div className="md:p-4 py-2 pt-4 block">
                                    <PrimaryButton text={"Chat with Sida"} to={"sida"} typ="dark" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>

    )
}

export default Navbar