import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'

import SidaCharakter from "../SidaCharakter";
import SidaMessage from "../tools/SidaMessage";


import Loader from "../tools/Loader";

const libArr = [
  {
    icon: faClipboardList,
    bgColor: "bg-accentColor-100",
    title: "magna Dolore",
    desc: "Est Lorem"
  }, {
    icon: faClipboardList,
    bgColor: "bg-accentColor-200",
    title: "Erat magna",
    desc: "aliquyam"
  }, {
    icon: faClipboardList,
    bgColor: "bg-accentColor-300",
    title: "Dolore magna",
    desc: "aliquyam"
  }, {
    icon: faClipboardList,
    bgColor: "bg-accentColor-400",
    title: "Quis nostrud",
    desc: "exerci tation"
  }, {
    icon: faClipboardList,
    bgColor: "bg-accentColor-500",
    title: "nostrud Quis",
    desc: "Est Lorem"
  }
]

const Header = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2000));
      setLoading((loading) => !loading);
    };

    loadData();
  }, [])

  return (
    <div className="bg-primaryBG py-2.5 w-full border-b border-gray-200">
      <div className="py-28 container mx-auto text-center">
        <div className="flex flex-col justify-center items-center md:py-24 px-0 gap-6">
          <div className="flex flex-col justify-center items-center py-11 px-0 gap-4">
            <h1 className="mb-4 text-4xl sm:text-6xl font-extrabold leading-none tracking-tight text-gray-900">At vero eos et accusam et justo duo <span className="text-blue-600 dark:text-blue-500">dolores accusam </span> rebum.</h1>
            <p className="text-lg sm:text-xl font-body">Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
          </div>
          <div className="w-full border rounded-2xl p-4">

            <div className="flex flex-col items-center w-full h-full border rounded-2xl">
              <div className="w-full bg-white flex items-center gap-2 text-left container rounded-t-2xl">
                <SidaCharakter height="60" width="60" />
                <div className="p-2 my-2">
                  <h3 className="text-lg font-typeFont">Sida</h3>
                  <h5 className="text-sm text-green-500 font-typeFont">Online</h5>
                </div>
              </div>
              <div className="relative w-full h-half-screen bg-gray-50 flex items-center gap-2 text-left container rounded-b-2xl">
                {loading ? <Loader /> :
                  <SidaMessage
                    typeEffect={true}
                    charW="40px"
                    small={true}
                    btn={true}
                    text={`Hallo! 👋 ich bin Sida, dein MS-Coach`}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto text-center py-11">
          <div className="flex flex-col justify-center items-center px-0 gap-4">
            <h1 className="mb-4 text-4xl sm:text-6xl font-extrabold leading-none tracking-tight text-gray-900 ">Sadipscing elitr Consetetur</h1>
            <p className="text-lg sm:text-xl">Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet</p>
          </div>

          <div className="flex flex-row justify-center items-center py-5 md:py-11 px-0 gap-8 flex-wrap">
            {libArr?.map((lib, i) => {
              return (
                <div key={i} className="flex flex-col items-center pb-10">
                  <div className={`cursor-pointer relative inline-flex items-center justify-center w-32 h-32 overflow-hidden ${lib.bgColor} rounded-full border hover:border-primaryColor`}>
                    <span className="font-medium text-blue-600 dark:text-gray-300">
                      <FontAwesomeIcon icon={lib.icon} className="text-primaryColor fa-4x " />
                    </span>
                  </div>
                  <h5 className="mb-1 text-lg sm:text-xl font-medium text-gray-900  dark:text-white font-typeFont">{lib.title}</h5>
                  <span className="text-sm text-gray-500 dark:text-gray-400">{lib.desc}</span>
                </div>
              )
            })}

          </div>
        </div>
      </div>
    </div>
  );

};

export default Header;
