import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";

import Loader from "./layout/tools/Loader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import "survey-core/modern.min.css";
import "survey-core/defaultV2.min.css";


//For change the default Icons -> https://surveyjs.io/form-library/documentation/icons
//import { SvgRegistry } from "survey-core";
// var svg = '<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M11.35 1.34999L10.65 0.649994L6.05002 5.24999L1.35002 0.649994L0.650024 1.34999L5.25002 6.04999L0.650024 10.65L1.35002 11.35L6.05002 6.74999L10.65 11.35L11.35 10.65L6.75002 6.04999L11.35 1.34999Z"/></svg>';
// SvgRegistry.registerIconFromSvg("v2check", svg);
// SvgRegistry.registerIconFromSvg("moderncheck", svg);



StylesManager.applyTheme("defaultV2");



const SurveyCreator = ({ schema }) => {

  const [loading, setLoading] = useState(true);

  const nextBtn = useRef(null);
  const prevBtn = useRef(null);
  const compBtn = useRef(null);



  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2000));
      setLoading((loading) => !loading);
    };

    loadData();
  }, [])


  const navigate = useNavigate();

  const survey = new Model(schema);

  survey.showPreviewBeforeComplete = "showAllQuestions";

  //change page automatic without next button
  survey.goNextPageAutomatic = true;

  //hide default NavigationButtons
  survey.showNavigationButtons = true;

  //Add clear Button
  // survey.addNavigationItem({
  //   id: "survey_clear_current_page",
  //   title: "Seite leeren Test",
  //   visibleIndex: 49, // "Complete" button has the visibleIndex 50.
  //   action: () => {
  //     survey.currentPage.questions.forEach(function (question) {
  //       question.value = undefined;
  //     });
  //   },
  // });

  // oncomplete hook
  survey.onComplete.add((sender) => {
    // form submission data is in sender.data
    // const results = JSON.stringify(sender.data);
    const results = sender.data;
    // do whatever you need with this.
    console.log(results);
  });

  var myCss = {
    question: {
      content: "sd-question__content question_content_custom",
      titleOnAnswer: "question-title-answered"
    }
  };



  if (loading) {
    return <Loader aboulte={false} />
  } else {
    return <>
      <div className="flex justify-between items-center h-full flex-col">
        <div className="w-full">
          <div className="text-left w-full px-5 py-5">
            <button onClick={() => { navigate(-1) }} className="border-b ">
              <FontAwesomeIcon icon={faChevronLeft} className="text-primaryColor fa-sm mr-2" />
              <span className="font-typeFont">Zurück</span>
            </button>
          </div>
          <Survey model={survey} css={myCss} />
        </div>

        <div className="w-full px-5 py-5 flex justify-center gap-1">
          {/* <button ref={prevBtn} id="surveyPrev" onClick={() => { survey.prevPage() }} className="bg-DarkBg py-2 px-7 hover:bg-borderColorOnDarkBG">
            <FontAwesomeIcon icon={faChevronLeft} className="text-LightBG-100" />
          </button>
          <button ref={nextBtn} onClick={() => { survey.nextPage() }} className="bg-DarkBg py-2 px-7 hover:bg-borderColorOnDarkBG">
            <FontAwesomeIcon icon={faChevronRight} className="text-LightBG-100" />
          </button>
          <button ref={compBtn} onClick={() => { survey.completeLastPage() }} className="bg-DarkBg py-2 px-7 text-LightBG-100 hover:bg-borderColorOnDarkBG">
            <span>Complete</span>
          </button> */}
        </div>

      </div>



    </>;
  }

};

export default SurveyCreator;
